<template>
    <v-data-table
      :headers="headers"
      :items="details"
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-btn small outlined color="primary" :to="{ name: 'artworks', params: { id: $route.params.idExhibition }}">
            <v-icon left small>mdi-keyboard-return</v-icon>
            Retour aux œuvres
          </v-btn>
          <v-toolbar-title class="mx-4">Liste des détails</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="prepareCreateDetail('/' + apiPrefix + '/artworks/' + $route.params.idArtwork)">
            Nouveau détail
          </v-btn>
          <v-dialog v-model="editDialog" max-width="700px" persistent>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <template v-if="editedDetail.media != undefined">
                    <v-row>
                      <v-card
                        elevation="2"
                        tile
                      >
                        <img
                          :src="apiUrl + '/' + editedDetail.media.contentUrl"
                          alt=""
                        >
                      </v-card>
                    </v-row>
                  </template>
                  <template>
                    <v-row>
                      <v-col cols="8">
                        <v-file-input
                          label="Choisissez un média"
                          prepend-icon="mdi-image"
                          v-model="editedDetailFile"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="4" v-if="editedDetail.media != undefined">
                        <v-btn color="error" @click="deleteDetailFileLocally">
                          <v-icon left>mdi-delete</v-icon>
                          Retirer le média
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                  <v-row>
                    <v-col cols="12">
                      <label>Titre</label>
                      <tiptap-vuetify
                        label="Titre"
                        v-model="editedDetail.name"
                        :extensions="extensionsTitle"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <label>Description</label>
                      <tiptap-vuetify
                        label="Description"
                        v-model="editedDetail.description"
                        :extensions="extensions"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="readAllDetailAction">
                  Annuler
                </v-btn>
                <v-btn color="primary" text @click="createOrUpdateDetail">
                  Sauvegarder
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <delete-dialog
            :cancelFunction="readAllDetailAction"
            :confirmFunction="deleteDetail"
            :model="deleteDialog"
            title="Voulez-vous supprimer ce détail ?"
            description="<p>Cette action est irréversible.</p>"
          ></delete-dialog>
        </v-toolbar>
      </template>
      <template v-slot:body="{ items }">
        <draggable :list="items" tag="tbody" @change="sort($event)">
          <tr v-for="(item, index) in items" :key="index">
            <td class="text-center">
              <v-icon class="col__grab-icon">
                mdi-reorder-horizontal
              </v-icon>
            </td>
            <td class="text-center">
              <v-chip small>
              {{ item.sortOrder + 1 }}
              </v-chip>
            </td>
            <td class="text-center">
              <v-img
                v-if="item.media !== undefined"
                :src="apiUrl + '/' + item.media.contentUrl"
                aspect-ratio="1"
                max-width="70"
                alt=""
              ></v-img>
            </td>
            <td v-html="item.name">
            </td>
            <td>
              <v-btn class="mr-2" small color="success" @click="prepareUpdateDetail(item)">
                <v-icon left small>mdi-pencil</v-icon>
                Éditer
              </v-btn>
              <v-btn color="error" small @click="prepareDeleteDetail(item)">
                <v-icon left small>mdi-delete</v-icon>
                Supprimer
              </v-btn>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
</template>

<script>
  import draggable from 'vuedraggable'
  import { mapState, mapActions } from 'vuex'
  import { TiptapVuetify, Bold, Italic, Strike, Underline, Paragraph } from 'tiptap-vuetify'
  import DeleteDialog from './../components/DeleteDialog.vue'

  export default {
    components: {
      draggable,
      TiptapVuetify,
      DeleteDialog,
    },
    data () {
      return {
        headers: [
          { text: '', sortable: false, align: 'center', width: '50px' },
          { text: 'Ordre', sortable: false, align: 'center', width: '100px' },
          { text: 'Miniature', value: 'image', sortable: false, align: 'center', width: '100px' },
          { text: 'Titre', value: 'name', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        editDialog: false,
        deleteDialog: false,
        extensionsTitle: [
          Italic,
        ],
        extensions: [
          Bold,
          Italic,
          Underline,
          Strike,
          Paragraph
        ],
        file: null,
        apiUrl: process.env.VUE_APP_API_URL,
        apiPrefix: process.env.VUE_APP_API_PREFIX,
      }
    },
    computed: {
      formTitle () {
        return this.editedDetail.id === undefined ? 'Nouvelle exposition' : 'Édition de l\'exposition'
      },
      ...mapState(
        'details',
        {
          details: 'items',
          editedDetail: 'editedItem',
          actionStatusDetail: 'actionStatus',
        }
      ),
      editedDetailFile: {
        get: function() {
          return this.$store.state.details.editedItemFile
        },
        set: function(newValue) {
          this.setEditedDetailFile(newValue)
        }
      }
    },
    watch: {
      actionStatusDetail(value) {
        if (['create', 'update'].includes(value)) {
          this.editDialog = true
          this.deleteDialog = false
        } else if (value === 'delete') {
          this.deleteDialog = true
          this.editDialog = false
        } else {
          this.editDialog = false
          this.deleteDialog = false
        }
      }
    },

    mounted () {
      this.getDetails('artworks/' + this.$route.params.idArtwork)
    },

    methods: {
      ...mapActions(
        'details',
        {
          getDetails: 'getItems',
          createOrUpdateDetail: 'createOrUpdateItem',
          deleteDetail: 'deleteItem',
          setEditedDetailFile: 'setEditedItemFile',
          readAllDetailAction: 'readAllAction',
          prepareCreateDetail: 'prepareCreate',
          prepareUpdateDetail: 'prepareUpdate',
          prepareDeleteDetail: 'prepareDelete',
          deleteDetailFileLocally: 'deleteItemFileLocally',
          sortDetails: 'sortItems',
        }
      ),
      sort(event) {
        this.sortDetails({
          oldIndex: event.moved.oldIndex,
          newIndex: event.moved.newIndex,
          item: event.moved.element
        })
      }
    },
  }
</script>