<template>
  <v-dialog v-model="model" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>
      <v-card-text v-html="description">
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelFunction">Annuler</v-btn>
        <v-btn color="error" text @click="confirmFunction">Supprimer</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DeleteDialog',
    props: ['title', 'description', 'cancelFunction', 'confirmFunction', 'model']
  }
</script>