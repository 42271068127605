<template>
  <p>Deconnexion en cours...</p>
</template>

<script>
  import { mapActions } from 'vuex'
  import router from './../router'

  export default {
    components: {
    },
    data () {
      return {
      }
    },
    computed: {
    },
    methods: {
      ...mapActions(
        'login',
        ['logout']
      ),
    },
    mounted() {
      this.logout().then(() => {
        router.push({name: 'login'})
      })
    }
  }
</script>