<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card>
          <v-card-text>
            <form>
              <v-text-field
                v-model="email"
                label="Email"
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Password"
                required
                :type="'password'"
              ></v-text-field>
              <v-btn class="mr-4" color="primary" @click="submit">
                Se connecter
              </v-btn>
            </form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    components: {
    },
    data () {
      return {
        'email': null,
        'password': null,
      }
    },
    watch: {
      token: function (val) {
        if (val) {
          this.$router.push({name: 'exhibitions'})
        }
      },
    },
    computed: {
      ...mapState(
        'login',
        ['token']
      ),
    },
    methods: {
      ...mapActions(
        'login',
        ['login']
      ),
      submit() {
        this.login({
          'email': this.email,
          'password': this.password
        })
      }
    },
  }
</script>