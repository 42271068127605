<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title>ExpoAdmin</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn :href="apiFrontUrl" target="_blank" text small>
        <span class="mr-2">Voir les expositions</span>
        <v-icon small>mdi-open-in-new</v-icon>
      </v-btn>
      <v-btn v-if="token !== null" :to="{name: 'logout'}" text small>
        <span class="mr-2">Deconnexion</span>
        <v-icon small>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-row dense>
          <v-col>
            <router-view/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'


export default {
  name: 'App',
  data () {
    return {
      apiFrontUrl: process.env.VUE_APP_FRONT_URL,
    }
  },
  computed: {
    ...mapState(
      'login',
      ['token']
    ),
  },
  methods: {
    ...mapActions(
      'login',
      ['loadTokenFromLocalStorage']
    ),
  },
  mounted() {
    this.loadTokenFromLocalStorage()
  }
};
</script>

<style>
  img {
    display: block;
    max-width: 100%;
  }

  .col__grab-icon {
    cursor: move;
  }
</style>