import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Exhibitions from '../views/Exhibitions.vue'
import Artworks from '../views/Artworks.vue'
import Details from '../views/Details.vue'
import jwtDecode from 'jwt-decode'

Vue.use(VueRouter)

const routes = [
  {
    name: 'login',
    path: '/login',
    component: Login,
    beforeEnter (to, from, next) {
      redirectToAdmin(to, from, next)
    },
  },
  {
    name: 'logout',
    path: '/logout',
    component: Logout,
  },
  {
    name: 'exhibitions',
    path: '/',
    component: Exhibitions,
    beforeEnter (to, from, next) {
      redirectToLogin(to, from, next)
    },
  },
  {
    name: 'artworks',
    path: '/:id/artworks',
    component: Artworks,
    beforeEnter (to, from, next) {
      redirectToLogin(to, from, next)
    },
  },
  {
    name: 'details',
    path: '/:idExhibition/artworks/:idArtwork/details',
    component: Details,
    beforeEnter (to, from, next) {
      redirectToLogin(to, from, next)
    },
  },
]

const router = new VueRouter({
  routes
})

function redirectToLogin(to, from, next)
{
  let token = localStorage.getItem('token')

  if (token === null) {
    router.push({ name: 'login' })
  }

  let decodedToken = jwtDecode(token)

  if ((Date.now() / 1000) > decodedToken.exp) {
    router.push({ name: 'login' })
  }
  
  next()
}

function redirectToAdmin(to, from, next)
{
  let token = localStorage.getItem('token')

  if (token !== null) {
    let decodedToken = jwtDecode(token)

    if ((Date.now() / 1000) <= decodedToken.exp) {
      router.push({ name: 'exhibitions' })
    }
  }

  next()
}

export default router
