import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login.js'
import exhibitions from './modules/exhibitions.js'
import artworks from './modules/artworks.js'
import details from './modules/details.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    'login': login,
    'exhibitions': exhibitions,
    'artworks': artworks,
    'details': details,
  }
})
