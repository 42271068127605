<template>
    <v-data-table
      :headers="headers"
      :items="artworks"
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-btn small outlined color="primary" :to="{ name: 'exhibitions'}">
            <v-icon left small>mdi-keyboard-return</v-icon>
            Retour aux expositions
          </v-btn>
          <v-toolbar-title class="mx-4">Liste des œuvres</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="prepareCreateArtwork('/' + apiPrefix + '/exhibitions/' + $route.params.id)">
            Nouvelle œuvre
          </v-btn>
          <v-dialog v-model="editDialog" max-width="700px" persistent>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <template v-if="editedArtwork.image != undefined">
                    <v-row>
                      <v-card
                        elevation="2"
                        tile
                      >
                        <img
                          :src="apiUrl + '/' + editedArtwork.image.contentUrl"
                          alt=""
                        >
                      </v-card>
                    </v-row>
                  </template>
                  <template>
                    <v-row>
                      <v-col cols="8">
                        <v-file-input
                          label="Choisissez une image"
                          prepend-icon="mdi-image"
                          v-model="editedArtworkFile"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="4" v-if="editedArtwork.image != undefined">
                        <v-btn color="error" @click="deleteArtworkFileLocally">
                          <v-icon left>mdi-delete</v-icon>
                          Retirer l'image
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedArtwork.name" label="Titre"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="readAllArtworkAction">
                  Annuler
                </v-btn>
                <v-btn color="primary" text @click="createOrUpdateArtwork">
                  Sauvegarder
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <delete-dialog
            :cancelFunction="readAllArtworkAction"
            :confirmFunction="deleteArtwork"
            :model="deleteDialog"
            title="Voulez-vous supprimer cette œuvres ?"
            description="<p>Cette action est irréversible. Les détails associés à cette œuvre seront définitivement perdus.</p>"
          ></delete-dialog>
        </v-toolbar>
      </template>
      <template v-slot:body="{ items }">
        <draggable :list="items" tag="tbody" @change="sort($event)">
          <tr v-for="(item, index) in items" :key="index">
            <td class="text-center">
              <v-icon class="col__grab-icon">
                mdi-reorder-horizontal
              </v-icon>
            </td>
            <td class="text-center">
              <v-chip small>
              {{ item.sortOrder + 1 }}
              </v-chip>
            </td>
            <td class="text-center">
              <v-img
                v-if="item.image !== undefined"
                :src="apiUrl + '/' + item.image.contentUrl"
                aspect-ratio="1"
                max-width="70"
                alt=""
              ></v-img>
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              <v-btn
                class="mr-2"
                small color="info"
                :to="{ name: 'details', params: { idExhibition: $route.params.id, idArtwork: item.id }}"
              >
                <v-icon left small>mdi-image-multiple</v-icon>
                Accéder aux détails
              </v-btn>
              <v-btn class="mr-2" small color="success" @click="prepareUpdateArtwork(item)">
                <v-icon left small>mdi-pencil</v-icon>
                Éditer
              </v-btn>
              <v-btn color="error" small @click="prepareDeleteArtwork(item)">
                <v-icon left small>mdi-delete</v-icon>
                Supprimer
              </v-btn>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
</template>

<script>
  import draggable from 'vuedraggable'
  import { mapState, mapActions } from 'vuex'
  import DeleteDialog from './../components/DeleteDialog.vue'

  export default {
    components: {
      draggable,
      DeleteDialog,
    },
    data () {
      return {
        headers: [
          { text: '', sortable: false, align: 'center', width: '50px' },
          { text: 'Ordre', sortable: false, align: 'center', width: '100px' },
          { text: 'Miniature', value: 'image', sortable: false, align: 'center', width: '100px' },
          { text: 'Titre', value: 'name', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        editDialog: false,
        deleteDialog: false,
        file: null,
        apiUrl: process.env.VUE_APP_API_URL,
        apiPrefix: process.env.VUE_APP_API_PREFIX,
      }
    },
    computed: {
      formTitle () {
        return this.editedArtwork.id === undefined ? 'Nouvelle œuvre' : 'Édition de l\'œuvre'
      },
      ...mapState(
        'artworks',
        {
          artworks: 'items',
          editedArtwork: 'editedItem',
          actionStatusArtwork: 'actionStatus',
        }
      ),
      editedArtworkFile: {
        get: function() {
          return this.$store.state.artworks.editedItemFile
        },
        set: function(newValue) {
          this.setEditedArtworkFile(newValue)
        }
      }
    },
    watch: {
      actionStatusArtwork(value) {
        if (['create', 'update'].includes(value)) {
          this.editDialog = true
          this.deleteDialog = false
        } else if (value === 'delete') {
          this.deleteDialog = true
          this.editDialog = false
        } else {
          this.editDialog = false
          this.deleteDialog = false
        }
      }
    },

    mounted () {
      this.getArtworks('exhibitions/' + this.$route.params.id)
    },

    methods: {
      ...mapActions(
        'artworks',
        {
          getArtworks: 'getItems',
          createOrUpdateArtwork: 'createOrUpdateItem',
          deleteArtwork: 'deleteItem',
          setEditedArtworkFile: 'setEditedItemFile',
          readAllArtworkAction: 'readAllAction',
          prepareCreateArtwork: 'prepareCreate',
          prepareUpdateArtwork: 'prepareUpdate',
          prepareDeleteArtwork: 'prepareDelete',
          deleteArtworkFileLocally: 'deleteItemFileLocally',
          sortArtworks: 'sortItems',
        }
      ),
      sort(event) {
        this.sortArtworks({
          oldIndex: event.moved.oldIndex,
          newIndex: event.moved.newIndex,
          item: event.moved.element
        })
      }
    },
  }
</script>