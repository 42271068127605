<template>
    <v-data-table
      :headers="headers"
      :items="exhibitions"
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Liste des expositions</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="prepareCreateExhibition">
            Nouvelle exposition
          </v-btn>
          <v-dialog v-model="editDialog" max-width="700px" persistent>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <template v-if="editedExhibition.image != undefined">
                    <v-row>
                      <v-card
                        elevation="2"
                        tile
                      >
                        <img
                          :src="apiUrl + '/' + editedExhibition.image.contentUrl"
                          alt=""
                        >
                      </v-card>
                    </v-row>
                  </template>
                  <template>
                    <v-row>
                      <v-col cols="8">
                        <v-file-input
                          label="Choisissez une image"
                          prepend-icon="mdi-image"
                          v-model="editedExhibitionFile"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="4" v-if="editedExhibition.image != undefined">
                        <v-btn color="error" @click="deleteExhibitionFileLocally">
                          <v-icon left>mdi-delete</v-icon>
                          Retirer l'image
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedExhibition.name" label="Titre"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <label>Description</label>
                      <tiptap-vuetify
                        label="Description"
                        v-model="editedExhibition.description"
                        :extensions="extensions"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="readAllExhibitionAction">
                  Annuler
                </v-btn>
                <v-btn color="primary" text @click="createOrUpdateExhibition">
                  Sauvegarder
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <delete-dialog
            :cancelFunction="readAllExhibitionAction"
            :confirmFunction="deleteExhibition"
            :model="deleteDialog"
            title="Voulez-vous supprimer cette exposition ?"
            description="<p>Cette action est irréversible. Les oeuvres associées à cette exposition seront définitivement perdues.</p>"
          ></delete-dialog>
        </v-toolbar>
      </template>
      <template v-slot:body="{ items }">
        <draggable :list="items" tag="tbody" @change="sort($event)">
          <tr v-for="(item, index) in items" :key="index">
            <td class="text-center">
              <v-icon class="col__grab-icon">
                mdi-reorder-horizontal
              </v-icon>
            </td>
            <td class="text-center">
              <v-chip small>
              {{ item.sortOrder + 1 }}
              </v-chip>
            </td>
            <td class="text-center">
              <v-img
                v-if="item.image !== undefined"
                :src="apiUrl + '/' + item.image.contentUrl"
                aspect-ratio="1"
                max-width="70"
                alt=""
              ></v-img>
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              <v-btn
                class="mr-2"
                small color="info"
                :to="{ name: 'artworks', params: { id: item.id }}"
              >
                <v-icon left small>mdi-image-multiple</v-icon>
                Accéder aux oeuvres
              </v-btn>
              <v-btn class="mr-2" small color="success" @click="prepareUpdateExhibition(item)">
                <v-icon left small>mdi-pencil</v-icon>
                Éditer
              </v-btn>
              <v-btn color="error" small @click="prepareDeleteExhibition(item)">
                <v-icon left small>mdi-delete</v-icon>
                Supprimer
              </v-btn>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
</template>

<script>
  import draggable from 'vuedraggable'
  import { mapState, mapActions } from 'vuex'
  import { TiptapVuetify, Bold, Italic, Strike, Underline, Paragraph } from 'tiptap-vuetify'
  import DeleteDialog from './../components/DeleteDialog.vue'

  export default {
    components: {
      draggable,
      TiptapVuetify,
      DeleteDialog,
    },
    data () {
      return {
        headers: [
          { text: '', sortable: false, align: 'center', width: '50px' },
          { text: 'Ordre', sortable: false, align: 'center', width: '100px' },
          { text: 'Miniature', value: 'image', sortable: false, align: 'center', width: '100px' },
          { text: 'Titre', value: 'name', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        editDialog: false,
        deleteDialog: false,
        extensions: [
          Bold,
          Italic,
          Underline,
          Strike,
          Paragraph
        ],
        file: null,
        apiUrl: process.env.VUE_APP_API_URL,
      }
    },
    computed: {
      formTitle () {
        return this.editedExhibition.id === undefined ? 'Nouvelle exposition' : 'Édition de l\'exposition'
      },
      ...mapState(
        'exhibitions',
        {
          exhibitions: 'items',
          editedExhibition: 'editedItem',
          actionStatusExhibition: 'actionStatus',
        }
      ),
      editedExhibitionFile: {
        get: function() {
          return this.$store.state.exhibitions.editedItemFile
        },
        set: function(newValue) {
          this.setEditedExhibitionFile(newValue)
        }
      }
    },
    watch: {
      actionStatusExhibition(value) {
        if (['create', 'update'].includes(value)) {
          this.editDialog = true
          this.deleteDialog = false
        } else if (value === 'delete') {
          this.deleteDialog = true
          this.editDialog = false
        } else {
          this.editDialog = false
          this.deleteDialog = false
        }
      }
    },

    mounted () {
      this.getExhibitions()
    },

    methods: {
      ...mapActions(
        'exhibitions',
        {
          getExhibitions: 'getItems',
          createOrUpdateExhibition: 'createOrUpdateItem',
          deleteExhibition: 'deleteItem',
          setEditedExhibitionFile: 'setEditedItemFile',
          readAllExhibitionAction: 'readAllAction',
          prepareCreateExhibition: 'prepareCreate',
          prepareUpdateExhibition: 'prepareUpdate',
          prepareDeleteExhibition: 'prepareDelete',
          deleteExhibitionFileLocally: 'deleteItemFileLocally',
          sortExhibitions: 'sortItems',
        }
      ),
      sort(event) {
        this.sortExhibitions({
          oldIndex: event.moved.oldIndex,
          newIndex: event.moved.newIndex,
          item: event.moved.element
        })
      }
    },
  }
</script>