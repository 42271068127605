import axios from 'axios'
import router from './../router'
const apiHeader = { headers: { Accept: 'application/json' } }
const axiosInstance = axios.create()
axiosInstance.defaults.baseURL = process.env.VUE_APP_API_URL
const apiPrefix = process.env.VUE_APP_API_PREFIX

axiosInstance.interceptors.request.use(function (config) {
  if (config.url !== 'authentication_token') {
    let token = localStorage.getItem('token')
    config.headers.Authorization = 'Bearer ' + token
  }

  return config;
})

axiosInstance.interceptors.response.use(function (response) {
  if (response.status === 401) {
    router.push({name: 'login'})
  }

  return response;
})

export default {
  login: {
    endpoint: 'authentication_token',
    login(identifiers) {      
      return axiosInstance.post(
        this.endpoint,
        identifiers,
        apiHeader
      )
    },
  },
  exhibitions: {
    prefix: apiPrefix + '/',
    endpoint: 'exhibitions',
    read() {
      return axiosInstance.get(
        this.prefix + this.endpoint,
        apiHeader
      )
    },
    createOrUpdate(editedItem, uriFile) {
      if (uriFile !== undefined) {
        editedItem.image = uriFile
      }

      if (editedItem.id === undefined) {
        return axiosInstance.post(
          this.prefix + this.endpoint,
          editedItem,
          apiHeader
        )
      } else {
        return axiosInstance.put(
          this.prefix + this.endpoint + '/' + editedItem.id,
          editedItem,
          apiHeader
        )
      }
    },
    delete(editedItem) {
      return axiosInstance.delete(
        this.prefix + this.endpoint + '/' + editedItem.id,
      )
    }
  },
  artworks: {
    prefix: apiPrefix + '/',
    endpoint: 'artworks',
    read(exhibition) {
      return axiosInstance.get(
        this.prefix + exhibition + '/' + this.endpoint,
        apiHeader
      )
    },
    createOrUpdate(editedItem, uriFile) {
      if (uriFile !== undefined) {
        editedItem.image = uriFile
      }

      if (editedItem.id === undefined) {
        return axiosInstance.post(
          this.prefix + this.endpoint,
          editedItem,
          apiHeader
        )
      } else {
        return axiosInstance.put(
          this.prefix + this.endpoint + '/' + editedItem.id,
          editedItem,
          apiHeader
        )
      }
    },
    delete(editedItem) {
      return axiosInstance.delete(
        this.prefix + this.endpoint + '/' + editedItem.id,
      )
    }
  },
  details: {
    prefix: apiPrefix + '/',
    endpoint: 'details',
    read(artwork) {
      return axiosInstance.get(
        this.prefix + artwork + '/' + this.endpoint,
        apiHeader
      )
    },
    createOrUpdate(editedItem, uriFile) {
      if (uriFile !== undefined) {
        editedItem.media = uriFile
      }

      if (editedItem.id === undefined) {
        return axiosInstance.post(
          this.prefix + this.endpoint,
          editedItem,
          apiHeader
        )
      } else {
        return axiosInstance.put(
          this.prefix + this.endpoint + '/' + editedItem.id,
          editedItem,
          apiHeader
        )
      }
    },
    delete(editedItem) {
      return axiosInstance.delete(
        this.prefix + this.endpoint + '/' + editedItem.id,
      )
    }
  },
  files: {
    prefix: apiPrefix + '/',
    endpoint: 'files',
    read() {
      return axiosInstance.get(
        this.prefix + this.endpoint,
        apiHeader
      )
    },
    create(file) {
      var formData = new FormData()
      formData.append('file', file)

      return axiosInstance.post(
        this.prefix + this.endpoint,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
    },
  }
}