import api from './../../services/api.js'

export default {
  namespaced: true,
  state: {
    token: null,
  },
  mutations: {
    SET_TOKEN(state, data) {
      if (data === null) {
        state.token = null
        delete localStorage.token
      } else {
        state.token = data
        localStorage.token = data
      }
    },
  },
  actions: {
    // Appels API
    loadTokenFromLocalStorage({commit}) {
      commit('SET_TOKEN', localStorage.getItem('token'))
    },
    login({commit}, identifiers) {
      api.login.login(identifiers).then(response => {
        commit('SET_TOKEN', response.data.token)
      })
    },
    logout({commit}) {
      commit('SET_TOKEN', null)
    },
  }
}