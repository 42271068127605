import api from './../../services/api.js'

export default {
  namespaced: true,
  state: {
    items: [],
    editedItemIndex: -1,
    editedItem: {},
    defaultItem: {
      name: '',
      sortOrder: -1,
    },
    editedItemFile: null,
    actionStatus: 'read_all',
  },
  mutations: {
    SET_ITEMS(state, data) {
      state.items = data
    },
    SET_EDITED_ITEM(state, data) {
      state.editedItem = data

      if (data === null) {
        state.editedItemIndex = -1
      } else {
        state.editedItemIndex = state.items.findIndex(
          (element) => element.id === data.id
        )
      }
    },
    SET_EDITED_ITEM_INDEX(state, data) {
      state.editedItemIndex = data
    },
    SET_EDITED_ITEM_FILE(state, data) {
      state.editedItemFile = data
    },
    SET_ACTION_STATUS(state, data) {
      state.actionStatus = data
    },
  },
  actions: {
    // Mécanique interne à la vue
    readAllAction({commit, state}) {
      commit('SET_ACTION_STATUS', 'read_all')
      commit('SET_EDITED_ITEM', Object.assign({}, state.defaultItem))
      commit('SET_EDITED_ITEM_FILE', null)
    },
    prepareCreate({commit, state}, exhibition) {
      commit('SET_ACTION_STATUS', 'create')
      let item = Object.assign({}, state.defaultItem)
      item.exhibition = exhibition
      commit('SET_EDITED_ITEM', item)
    },
    prepareUpdate({commit}, item) {
      commit('SET_ACTION_STATUS', 'update')
      commit('SET_EDITED_ITEM', Object.assign({}, item))
    },
    prepareDelete({commit}, item) {
      commit('SET_ACTION_STATUS', 'delete')
      commit('SET_EDITED_ITEM', Object.assign({}, item))
    },
    setEditedItemFile({commit}, file) {
      commit('SET_EDITED_ITEM_FILE', file)
    },
    deleteItemFileLocally({commit, state}) {
      let editedItem = Object.assign({}, state.editedItem)
      editedItem.image = null
      commit('SET_EDITED_ITEM', editedItem)
    },
    // Appels API
    getItems({commit}, exhibition) {
      api.artworks.read(exhibition).then(response => {
        commit('SET_ITEMS', response.data)
      })
    },
    createOrUpdateItem({commit, state, dispatch}) {
      let editedItem = Object.assign({}, state.editedItem)

      if (editedItem.sortOrder === -1) {
        editedItem.sortOrder = state.items.length
      }

      let exhibitionUri = state.editedItem.exhibition.replace(/\/api\//g,'')

      if (state.editedItemFile) {
        api.files.create(state.editedItemFile).then(response => {
          commit('SET_EDITED_ITEM_FILE', null)

          api.artworks.createOrUpdate(editedItem, response.data['@id']).finally(() => {
            dispatch('readAllAction')
            dispatch('getItems', exhibitionUri)
          })
        })
      } else {
        if (editedItem.image !== null) {
          delete editedItem.image
        }

        api.artworks.createOrUpdate(editedItem).finally(() => {
          dispatch('readAllAction')
          dispatch('getItems', exhibitionUri)
        })
      }
    },
    deleteItem({state, dispatch}) {
      let exhibitionUri = state.editedItem.exhibition.replace(/\/api\//g,'')

      api.artworks.delete(state.editedItem).finally(() => {
        dispatch('readAllAction')
        dispatch('getItems', exhibitionUri)
      })
    },
    sortItems({commit, state, dispatch}, data) {
      commit('SET_ACTION_STATUS', 'sort')
      let begin = 0
      let end = 0
      let iteration = 0
      let promises = []
      
      if (data.oldIndex < data.newIndex) {
        begin = data.oldIndex + 1
        end = data.newIndex
        iteration = -1
      } else {
        begin = data.newIndex
        end = data.oldIndex - 1
        iteration = 1
      }

      let editedItem = Object.assign({}, data.item)
      editedItem.sortOrder = data.newIndex
      delete editedItem.image
      promises.push(api.artworks.createOrUpdate(editedItem))

      let exhibitionUri = editedItem.exhibition.replace(/\/api\//g,'')
      
      for (let i = begin; i <= end; i++) {
        let editedItem = Object.assign({}, state.items[i])
        editedItem.sortOrder = i + iteration
        delete editedItem.image

        promises.push(api.artworks.createOrUpdate(editedItem))
      }

      Promise.all(promises).then(() => {
        dispatch('readAllAction')
        dispatch('getItems', exhibitionUri)
      })
    }
  }
}
  